import { DiscountDTO } from '@modeso/types__twint-lib-coupons';
import {ProductLineImage} from '@modeso/types__dgoods-products';

export interface Cart {
  productId: string;
  amount: number;
  receiverEmail: string;
  acceptTwintTC: boolean;
  terminalId: string;
  couponCode?:string;
  acceptProductTC: boolean;
  acceptDataProtection: boolean;
}
export interface CheckoutData {
  order: PendingOrder;
  messageId: string;
}

export interface PendingOrder {
  token: string;
  receiptURL: string;
  orderId: string;
}

export interface OrderMetadata {
  productName: string;
  orderReceiptUrl: string;
  price: number;
  date: string;
  reference: string;
  productLineImages: ProductLineImage[];
  discounts?: DiscountDTO;
  productCaption? :string;

}


export interface Order {
  productImages: ProductLineImages[];
  currency: string;
  price: number;
  productName: string;
  date: string;
  email: string;
  status: string;
  productConfiguration: ProductConfiguration;
  codeNumber?: string;
  discounts?: DiscountDTO;
  productCaption?:string;
}


export interface ProductLineImages {
  id: string;
  frontImage: string;
  imageSize: string;
  imageType: string;
}




//TODO: check if this needs to be exposed
export interface ProductConfiguration {
  config: Map<string, string>,
  text: Map<string, string>
}
