import {Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';
import { marked } from 'marked';
import { SafeHtmlAllowUrlPipe } from '../../shared/pipes/safeHtmlAllowUrl.pipe';

@Component({
  selector: 'app-contentbox-component',
  templateUrl: './contentbox.component.html',
  styleUrls: ['./contentbox.component.scss']
})
export class ContentBoxComponent implements OnInit {

  @Input() contentBox? : IContentBoxResponse;
  language: string;
  safeContentBoxText: SafeHtml;


  constructor(private pipe: SafeHtmlAllowUrlPipe) {}

  ngOnInit(): void {
    this.getBrowserLang();
    this.updateHtml();
  }

  private getBrowserLang(): void {
    const browserLanguage = navigator.language;
      if (browserLanguage.includes('de')) {
        this.language  = 'de_ch';
      } else if (browserLanguage.includes('fr')) {
        this.language  = 'fr_ch';
      } else if (browserLanguage.includes('it')) {
        this.language  = 'it_ch';
      } else {
        this.language  = 'en_us';
      }
  }


  private updateHtml(): void {
    if (this.contentBox != null) {
      this.safeContentBoxText = this.pipe.transform(marked.parse(this.contentBox.content[this.language]), 'string');
    }
  }

}
