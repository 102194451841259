import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { ActivatedRoute, Router } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { IAppStateProtocolModel } from '../../shared/models/appStateProtocol.model';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import * as fromActions from './../../state/app.state.actions';
import { map } from 'rxjs/operators';
import { ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';

const debug = Debug('dgoods:shop:FakeCheckinPage');

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-page-fakeStart',
  templateUrl: './onetimeurlStart.page.html',
  styleUrls: ['./onetimeurlStart.page.scss']
})
export class OneTimeUrlStartPage extends BasePageComponent implements OnInit {

  constructor(injector: Injector, private sessionProvider: DgoodsSessionStoreProvider,
              private route: ActivatedRoute, private router: Router, private store: Store<fromApp.AppState>,
              private productProvider: ProductStoreProvider
  ) {
    super(injector);
    debug('Test Start');
    debug('OneTimeUrlStartPage');
    let iosProtocol = this.route.snapshot.queryParamMap.get('returnAppScheme');
    const androidProtocol = this.route.snapshot.queryParamMap.get('returnAppPackage');
    debug(iosProtocol);
    debug(androidProtocol);
    if (iosProtocol === null || iosProtocol === undefined) {
      iosProtocol = "iosProtocol";
    }
    const protocol = androidProtocol ? androidProtocol : iosProtocol;
    const device = androidProtocol ? 'android' : 'ios';
    const appStateModel: IAppStateProtocolModel = {
      protocol,
      device,
    };
    localStorage.removeItem('protocol');
    localStorage.removeItem('device');
    this.store.dispatch(fromActions.updateStoreProtocol({ payload: appStateModel }));
    localStorage.setItem('protocol', protocol);
    localStorage.setItem('device', device);

    debug(this.route.snapshot.url[1].path);
    const urltoken = this.route.snapshot.url[1].path;
    this.sessionProvider.start({ checkInUrl: urltoken, issuer: protocol }).pipe(
      map(urlState => {
        if (urlState.isValidUrl) {
          debug('OneTimeUrlStartPage say\'s the url is valid');
          return this.router.navigate(['../../de-ch/landing-buy']);
        } else {
          debug('OneTimeUrlStartPage navigates to mocked checkin');
          this.router.navigateByUrl('/de-ch/login');
          return false;
        }
      })
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(this.sessionProvider.getSessionState$().subscribe(state => {
      debug(state);
      if (state.isValidUrl) {
        this.productProvider.fetchAllProducts();
        this.productProvider.getSpotlights$();
        debug('OneTimeUrlStartPage ngOnInit say\'s the url is valid');
        localStorage.removeItem('email');
        localStorage.removeItem('twintTC');
        localStorage.removeItem('checkInDate');
        localStorage.setItem('checkInDate', state.checkInDate.toString());
        sessionStorage.setItem('terminalId', state.terminalId);
        sessionStorage.setItem('isP2P', state.isP2P.toString());
        if (state.user) {
          if (state.user.email) {
            localStorage.setItem('email', state.user.email);
          }
        
        }
        this.subscriptions.push(
          this.productProvider.getFitleredProducts$().subscribe((products) => {
            if (products && products.length !== 0) {
              localStorage.setItem('products', JSON.stringify(products));
              if (state.productLineId) {
                const product = products.find(
                  (x) => x.productLineId === state.productLineId
                );
                if (product && !product.blocked) {
                  this.router.navigate([
                    `../../de-ch/product-buy/${state.productLineId}`,
                  ]);
                } else {
                  this.router.navigate(['../../de-ch/landing-buy']);
                }
              } else {
                this.router.navigate(['../../de-ch/landing-buy']);
              }
            }
          }));
      }
    }));
  }

}
