/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component,  Input , Output , EventEmitter, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { CouponEffectTypeEnum } from '@modeso/types__twint-lib-coupons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { CouponComponentDetails } from '../../shared/models/interfaces/coupon-component-details.interface';


@Component({
  selector: 'app-checkout-flyout-component',
  templateUrl: './checkout-flyout.component.html',
  styleUrls: ['./checkout-flyout.component.scss'],
})
export class CheckoutFlyoutComponent  implements AfterViewInit, OnDestroy  {


  @Input() product;
  @Input() price;
  @Input() disablePurchaseBtn;
  @Input() couponState;
  @Input() currency;
  // this input is used when the user goes from the main component to T&C so it helps to be redirected back
  @Input() redirectFrom;
  @Output() userPurchasedProduct = new EventEmitter<boolean>();
  @Output() generalTermsAndConditions = new EventEmitter<boolean>();
  @ViewChild('termsAndConditions', {static: false}) termsAndConditions: MatCheckbox;


  coupon: CouponComponentDetails | null = null;
  destroy$: Subject<any> = new Subject<any>();


  constructor(private changeDetector: ChangeDetectorRef,
              private router: Router,
              private appStateProvider: AppStateDispatcher,
              ) {}

  public get effectType(): typeof CouponEffectTypeEnum {
    return CouponEffectTypeEnum;
  }


  ngAfterViewInit(): void {

    this.appStateProvider.getNavigationData().pipe(
      takeUntil(this.destroy$),
    ).subscribe((navigationData)=>{

     const isTermsAndConditionsAccepted = navigationData.acceptTermsAndConditions;

     if(this.termsAndConditions != null){
        this.termsAndConditions.checked = isTermsAndConditionsAccepted;
        this.generalTermsAndConditions.emit(isTermsAndConditionsAccepted);
     }
     
    if(navigationData.couponCode != null){
      this.coupon = {
        discountAmount:navigationData.discountAmount,
        effectValue: navigationData.effectValue,
        discountPrice: navigationData.discountPrice,
        effectType: navigationData.effectType
      }
    }
  });

    this.changeDetector.detectChanges();

  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
 

  handleHrefClicks(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const href = target.getAttribute('href');

    if (target.tagName !== 'A' || href == null){
      return;
    }
      
    if (href === '#twintGTCLink') {
      this.openShopTermsAndConditions(event);
      return;
    } 
    
    if (href === '#twintDataProtection') {
      this.openDataProtection(event);
      return;
    }

    if (href === '#productTCLink') {
      this.openProductTermsAndConditions(event);
      return;
    }
  }
  

  purchaseProduct(): void {
    this.userPurchasedProduct.emit(true);
  }

  acceptTermsAndConditions(event: MatCheckboxChange): void {
    this.appStateProvider.saveNavigationData({ acceptTermsAndConditions:event.checked, refreshed: false });
  }


  private openProductTermsAndConditions(event: MouseEvent): void{
    this.router.navigate([`de-ch/terms-and-conditions/${this.product.productLineId}`,{redirectFrom: this.redirectFrom}],{skipLocationChange: true})
    // to keep the data from vanishing on going back and forth in the app
    this.appStateProvider.saveNavigationData({refreshed: false})
    event.preventDefault();
  }

 
  private openDataProtection(event: MouseEvent): void {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
        agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
        agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
        agbLanguage = 'it';
    } else {
        agbLanguage = 'en';
    }

    const pdfUrl =  `assets/pdf/dataProtection/dataProtection${agbLanguage.toUpperCase()}.pdf`

    window.open(pdfUrl, '_blank');
    // to disable navigation
    event.preventDefault();
  }

  private openShopTermsAndConditions(event: MouseEvent) : void {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
        agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
        agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
        agbLanguage = 'it';
    } else {
        agbLanguage = 'en';
    }
    const pdfUrl= `assets/pdf/shopTerms/${agbLanguage}/shopTerms.pdf`

    window.open(pdfUrl, '_blank');
    // to disable navigation
    event.preventDefault();
  }
}
