<div class="purchase-container">
    <div *ngIf="couponState && coupon" class="discount-amount-container">
      <span class="title">
        <span class="extra-margin">{{"dgoods_shop_discount" | localize | async}}</span>
        <span *ngIf="coupon.effectType === effectType.Percentage">{{coupon.effectValue.toFixed(0)}}%</span>
      </span>
      <span class="price">- {{currency}} {{coupon.discountAmount.toFixed(2)}}</span>
    </div>

    <div class="total-amount-container">
      <span class="title">{{"dgoods_shop_code_total_amount" | localize | async}}</span>
      <span class="price" *ngIf="!coupon">{{currency}} {{price?.toFixed(2)}}</span>
      <span class="price" *ngIf="coupon">{{currency}} {{coupon.discountPrice.toFixed(2)}}</span>
    </div>

    <div class="terms-conditions" *ngIf="product">

      <mat-checkbox  name="termsAndConditions" #termsAndConditions (change)="acceptTermsAndConditions($event)">
        <span 
          (click)="handleHrefClicks($event)"
          [innerHtml]="'dgoods_shop_accept_terms_and_conditions'  | localize | async 
          | replace:'{{productLineName}}': product.productLineCaption ?? product.productLineName
          | markdownToSafeHtmlImages
          ">
        </span>       
    
      </mat-checkbox>

    </div>


    <div>
      <dgoods-button (click)="purchaseProduct()" [disabled]="disablePurchaseBtn">
        {{"dgoods_shop_productbuyview_buybutton" | localize | async }}
      </dgoods-button>
    </div>

</div>


