import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import { Subscription } from 'rxjs';
import { ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';
import { HtmlEnitityDecoder } from '../../shared/classes/HtmlDecoder';

@Component({
  selector: 'app-page-terms-of-provider',
  templateUrl: './terms-of-provider.page.view.html',
  styleUrls: ['./terms-of-provider.page.view.scss']
})

export class TermsOfProviderPage implements IDGoodsButtonDelegate , OnInit, OnDestroy, AfterViewChecked {

  subscription: Subscription;
  termAndConditions;
  redirectFrom;
  decodeTermsAndCondition;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private productProvider: ProductStoreProvider,
            ) {
              this.redirectFrom = this.route.snapshot.paramMap.get('redirectFrom');
            }

  ngOnInit(): void {
    this.getTermsOfProvider();

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 200);

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

 
  onButtonClicked(): void {
      this.router.navigate([this.redirectFrom]);
  }

  private getProductId(): string {
    return this.route.snapshot.paramMap.get('productLineId');
  }

  private getTermsOfProvider(): void {
    this.subscription = this.productProvider.getTermsAndConditions$(this.getProductId()).subscribe(termAndConditionProvider => {
      this.termAndConditions = termAndConditionProvider;
      this.decodeTermsAndCondition = new HtmlEnitityDecoder().decodeHTML(this.termAndConditions);
    });
  }


}
